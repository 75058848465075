import React, { FC, Ref, RefObject, useCallback, useEffect, useRef, useState } from 'react'
import Inputmask from 'inputmask'
import { FieldInputProps } from 'formik/dist/types'
import { useIsomorphicLayoutEffect } from '@mtsbank/ui-kit'

import { TextField, TextFieldProps } from '@components/FormFields/TextField'

type Props = {
  field: {
    defaultValue: string
  } & FieldInputProps<string>
  mask:
    | string
    | {
        mask: string
        regex: RegExp
      }
} & TextFieldProps

export const InputMaskTextField: FC<Props> = ({ onFocus, onBlur, mask, field, ...rest }) => {
  const { setFieldValue, setFieldTouched } = rest.form
  let componentRef: Ref<HTMLInputElement | HTMLTextAreaElement> = useRef<HTMLInputElement>(null)
  const InputMask = useRef<Inputmask.Static>(null)
  const [inputMaskLoaded, setInputMaskLoaded] = useState(false)
  const isInitiating = useRef(true)
  const fieldValue: string = field.value
  const fieldName: string = field.name
  const isTouched = Boolean(rest.form.touched[fieldName])
  const [value, setValue] = useState<string>(fieldValue)

  if (rest.componentRef && rest.componentRef !== componentRef) {
    componentRef = rest.componentRef
  }

  const ref = (componentRef as RefObject<HTMLInputElement | HTMLTextAreaElement>).current
  const refInputMask = ref?.inputmask

  useIsomorphicLayoutEffect(() => {
    import('inputmask').then((inputmask) => {
      InputMask.current = inputmask.default
      setInputMaskLoaded(true)
    })
  })

  useEffect(() => {
    if (typeof fieldValue !== 'undefined' && fieldValue !== value) {
      setValue(fieldValue)
    }
  }, [fieldValue, value])

  useEffect(() => {
    if (ref && inputMaskLoaded && mask && isInitiating.current) {
      const isActiveElement = document.activeElement !== ref
      const inputMask = InputMask.current(mask as string)

      inputMask.mask(ref)
      inputMask.setValue(value || '')

      if (isInitiating.current) {
        isInitiating.current = false
      }

      setFieldValue(fieldName, ref.inputmask.unmaskedvalue())

      if (value) {
        ref.focus()
        if (isActiveElement) {
          ref.blur()
        }
      }
    }
  }, [InputMask, ref, refInputMask, value, mask, setFieldValue, fieldName, inputMaskLoaded])

  useEffect(() => {
    if (ref && InputMask.current && mask) {
      if (refInputMask && (value !== refInputMask.unmaskedvalue() || (value && !isTouched))) {
        const isActiveElement = document.activeElement !== ref

        refInputMask.setValue(value)

        if (isInitiating.current) {
          isInitiating.current = false
        }
        if (value) {
          ref.focus()
          if (isActiveElement) {
            ref.blur()
          }
        }
      }
    }
  }, [InputMask, ref, refInputMask, value, mask, isTouched, inputMaskLoaded])

  const handleChangeDebounced = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let { value } = event.target

      if (ref && mask && InputMask) {
        if (!event.target.inputmask) {
          InputMask.current(mask as string).mask(event.target)
        }

        if (event.target.inputmask) {
          value = event.target.inputmask.unmaskedvalue()
        }
      }

      setFieldValue(fieldName, value)
      setFieldTouched(fieldName, true, false)
    },
    [InputMask, ref, setFieldTouched, setFieldValue, mask, fieldName]
  )

  const onFocusBlur = useCallback(
    (event) => {
      const focused = event.type === 'focus'

      if (typeof onFocus !== 'undefined' && focused) {
        onFocus(event)
      }

      if (!focused) {
        if (field.onBlur) {
          field.onBlur(event)
        }

        if (typeof onBlur !== 'undefined' && !focused) {
          onBlur(event)
        }
      }
    },
    [onFocus, onBlur]
  )

  // for uncontrolled
  if (!isInitiating.current) {
    field.defaultValue = fieldValue
    delete field.value
  }

  return (
    <TextField
      field={field}
      {...rest}
      componentRef={componentRef}
      onChange={handleChangeDebounced}
      onBlur={onFocusBlur}
      onFocus={onFocusBlur}
    />
  )
}
