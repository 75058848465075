export const COEFFICIENT = 0.99

export const FIRST_SYMBOLS_CARD_NUMBER = 6

export const MTS_BY_PHONE_SERVICE_ID = '1150'

export const MTS_BY_ORDER_ID_SERVICE_ID = '16602'

export const MESSAGE_FOR_COMMISSION = `Удерживается МТС с\u00A0лицевого счета. {link href="https://moskva.mts.ru/personal/podderzhka/mobilnaya-svyaz/obsluzhivanie-abonentov/usloviya-popolneniya-schyota" target="_blank"} Подробнее {/link}`

export const LABEL_WITH_COMMISSION = `Комиссия {br} Удерживается МТС с\u00A0лицевого счета. {link href="https://moskva.mts.ru/personal/podderzhka/mobilnaya-svyaz/obsluzhivanie-abonentov/usloviya-popolneniya-schyota" target="_blank"} Подробнее {/link}`

export const BINS_WITH_NOT_COMMISSION = [
  '533213',
  '533736',
  '457802',
  '404266',
  '460530',
  '404267',
  '404268',
  '404224',
  '404269',
  '550583',
  '540616',
  '552618',
  '517955',
  '531733',
  '555973',
  '524602',
  '220028',
  '220028',
  '220028',
  '220028',
  '220028',
  '220028',
  '220028',
  '624342',
  '624342',
  '624342',
  '624342',
  '624342',
  '521159',
  '536672',
  '467756',
  '467757',
  '541435',
  '544123',
  '467758',
  '677684',
  '548035',
  '626304',
  '626304',
]
