import React, { FC, useRef } from 'react'
import { FieldProps } from 'formik'

import { TextFieldProps as KitTextFieldProps, useDidMount } from '@mtsbank/ui-kit'

import { TextField } from '@components/FormFields/TextField'

export interface PhoneFieldProps extends FieldProps, KitTextFieldProps {
  hint: string
  className?: string
  mask: string
}

export const PhoneField: FC<PhoneFieldProps> = ({ children, mask = '+7 (999) 999-99-99', ...rest }) => {
  const componentRef = useRef<HTMLInputElement>(null)

  useDidMount(() => {
    if (componentRef && componentRef.current) {
      import('inputmask').then((inputmask) => {
        inputmask.default(mask).mask(componentRef.current)
      })
    }
  })

  return <TextField {...rest} componentRef={componentRef} />
}
