import omitBy from 'lodash/omitBy'
import isEmpty from 'lodash/isEmpty'
import { TextField } from '@mtsbank/ui-kit'
import { ParameterTypeEnum, ServiceParameter } from '@generated/api'
import { ExtendedServiceParameter, ServiceParams } from '@components/MobilePayment/types'
import { clearPhoneNumber } from '@utils/formatPhoneNumber'
import { CheckBoxField } from '@components/FormFields/CheckboxField'
import { PasswordField } from '@components/FormFields/PasswordField'
import { PhoneField } from '@components/MobilePayment/serviceParamFormField/PhoneField'
import { InputMaskTextField } from '@components/MobilePayment/serviceParamFormField/InputMaskTextField'
import { WithoutAuthFormValues } from '@components/MobilePayment/withoutAuthorization/type'
import {
  BINS_WITH_NOT_COMMISSION,
  MTS_BY_PHONE_SERVICE_ID,
  MTS_BY_ORDER_ID_SERVICE_ID,
} from '@components/MobilePayment/utils/constants'

export enum ServiceParamNameKeys {
  Phone,
  PayerPhone,
  FiscalEmail,
  OrderNumber,
  Uniq,
  PaymentType,
  AutoPayId,
  BillRegId,
}

enum ServiceParamNames {
  id1 = 'id1',
  NUMBER = 'NUMBER',
  ordernumber = 'ordernumber',
  uniq = 'uniq',
  id2 = 'id2',
  payerPhone = 'payerPhone',
  payerEmail = 'payerEmail',
  AUTOPAYID = 'AUTOPAYID',
  billRegId = 'billRegId',
}

export const serviceParamTypeToComponent = {
  [ParameterTypeEnum.Unknown]: InputMaskTextField,
  [ParameterTypeEnum.StringField]: InputMaskTextField,
  [ParameterTypeEnum.NumberField]: InputMaskTextField,
  [ParameterTypeEnum.EmailField]: InputMaskTextField,
  [ParameterTypeEnum.DateField]: InputMaskTextField,
  [ParameterTypeEnum.DateMonthField]: InputMaskTextField,
  [ParameterTypeEnum.DateTimeField]: InputMaskTextField,
  [ParameterTypeEnum.CheckBox]: CheckBoxField,
  [ParameterTypeEnum.PhoneField]: PhoneField,
  [ParameterTypeEnum.PasswordField]: PasswordField,
  // [ParameterTypeEnum.ComboBox] : ,
  [ParameterTypeEnum.Amount]: TextField,
  [ParameterTypeEnum.PanField]: TextField,
  // [ParameterTypeEnum.HybridField] : ,
}

type ComplexServiceParameter = ExtendedServiceParameter | ServiceParameter

const InputToOutputParamsMapping = {
  [`${ServiceParamNames.id1}_${ParameterTypeEnum.PhoneField}`]: ServiceParamNameKeys.Phone,
  [`${ServiceParamNames.NUMBER}_${ParameterTypeEnum.PhoneField}`]: ServiceParamNameKeys.Phone,

  [`${ServiceParamNames.id1}_${ParameterTypeEnum.NumberField}`]: ServiceParamNameKeys.OrderNumber,
  [`${ServiceParamNames.id1}_${ParameterTypeEnum.StringField}`]: ServiceParamNameKeys.OrderNumber,
  [`${ServiceParamNames.payerEmail}_${ParameterTypeEnum.EmailField}`]: ServiceParamNameKeys.FiscalEmail,

  [`${ServiceParamNames.ordernumber}_${ParameterTypeEnum.StringField}`]: ServiceParamNameKeys.OrderNumber,
  [`${ServiceParamNames.uniq}_${ParameterTypeEnum.StringField}`]: ServiceParamNameKeys.Uniq,
  [`${ServiceParamNames.id2}_${ParameterTypeEnum.StringField}`]: ServiceParamNameKeys.PaymentType,

  [`${ServiceParamNames.payerPhone}_${ParameterTypeEnum.PhoneField}`]: ServiceParamNameKeys.PayerPhone,

  [`${ServiceParamNames.AUTOPAYID}_${ParameterTypeEnum.StringField}`]: ServiceParamNameKeys.AutoPayId,
  [`${ServiceParamNames.AUTOPAYID}_${ParameterTypeEnum.NumberField}`]: ServiceParamNameKeys.AutoPayId,

  [`${ServiceParamNames.billRegId}_${ParameterTypeEnum.StringField}`]: ServiceParamNameKeys.BillRegId,
}

const serviceParamMapping = {
  [ServiceParamNameKeys.Phone]: (data: Partial<WithoutAuthFormValues>, parameter: ComplexServiceParameter) =>
    clearPhoneNumber((data.phoneNumber || data[parameter.name] || '') as string) || parameter.defaultValue,
  [ServiceParamNameKeys.PayerPhone]: (data: Partial<WithoutAuthFormValues>, parameter: ComplexServiceParameter) =>
    clearPhoneNumber((data.fiscalPhone || data[parameter.name] || '') as string) || parameter.defaultValue,
  [ServiceParamNameKeys.FiscalEmail]: (data: Partial<WithoutAuthFormValues>, parameter: ComplexServiceParameter) =>
    data.fiscalEmail || data[parameter.name] || parameter.defaultValue,
  [ServiceParamNameKeys.OrderNumber]: (data: Partial<WithoutAuthFormValues>, parameter: ComplexServiceParameter) =>
    data.orderNumber || data[parameter.name] || parameter.defaultValue,
  [ServiceParamNameKeys.Uniq]: () => '',
  [ServiceParamNameKeys.PaymentType]: (data: Partial<WithoutAuthFormValues>, parameter: ComplexServiceParameter) =>
    data.paymentType || data[parameter.name] || parameter.defaultValue,
  [ServiceParamNameKeys.AutoPayId]: (data: Partial<WithoutAuthFormValues>, parameter: ComplexServiceParameter) =>
    data.autopaymentId || data[parameter.name] || parameter.defaultValue,
  [ServiceParamNameKeys.BillRegId]: (data: Partial<WithoutAuthFormValues>, parameter: ComplexServiceParameter) =>
    parameter.defaultValue,
}

const serviceParamToSiteFieldName = {
  [ServiceParamNameKeys.Phone]: 'phoneNumber',
  [ServiceParamNameKeys.PayerPhone]: 'fiscalPhone',
  [ServiceParamNameKeys.FiscalEmail]: 'fiscalEmail',
  [ServiceParamNameKeys.OrderNumber]: 'orderNumber',
  [ServiceParamNameKeys.Uniq]: 'uniq',
  [ServiceParamNameKeys.PaymentType]: 'paymentType',
  [ServiceParamNameKeys.AutoPayId]: 'autopaymentId',
  [ServiceParamNameKeys.BillRegId]: 'billRegId',
}

export const serverParamsPrepare = (inputParams: Array<ServiceParameter>): Array<ComplexServiceParameter> =>
  inputParams.reduce((result: Array<ExtendedServiceParameter>, item: ServiceParameter) => {
    result.push({
      mappedType: InputToOutputParamsMapping[`${item.name}_${item.type}`],
      ...item,
    })

    return result
  }, [])

type CollectServiceParamsOptions = {
  onlyRequired: boolean
}

export const collectServiceParams = (
  inputParams: Array<ComplexServiceParameter>,
  data: Partial<WithoutAuthFormValues>,
  opts: CollectServiceParamsOptions = { onlyRequired: false }
): ServiceParams =>
  omitBy(
    inputParams.reduce((result: ServiceParams, item: ComplexServiceParameter) => {
      if (!opts.onlyRequired || (opts.onlyRequired && item.required)) {
        if (typeof (item as ExtendedServiceParameter).mappedType !== 'undefined') {
          result[item.name] = String(serviceParamMapping[(item as ExtendedServiceParameter).mappedType](data, item))
        } else {
          result[item.name] = String(data[item.name] || item.defaultValue)
        }
      }

      return result
    }, {}),
    isEmpty
  )

export const collectSiteParams = (inputParams: Array<ComplexServiceParameter>, data): ServiceParams =>
  omitBy(
    inputParams.reduce((result: ServiceParams, item: ComplexServiceParameter) => {
      if (typeof (item as ExtendedServiceParameter).mappedType !== 'undefined') {
        result[serviceParamToSiteFieldName[(item as ExtendedServiceParameter).mappedType] || item.name] =
          data[item.name]
      } else {
        result[item.name] = data[item.name] || item.defaultValue
      }

      return result
    }, {}),
    isEmpty
  )

const MTS_SERVICE_IDS = [MTS_BY_PHONE_SERVICE_ID, MTS_BY_ORDER_ID_SERVICE_ID]

export const isMTS = (serviceId: string) => MTS_SERVICE_IDS.indexOf(serviceId) !== -1

export const binsWithNotCommissionForMTS = BINS_WITH_NOT_COMMISSION.reduce<Record<string, string>>((acc, el) => {
  acc[el] = el

  return acc
}, {})
